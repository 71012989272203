<!-- 实时数据 -->
<template>
    <div class="realData">
        <my-Titlebar>
            <template slot="TitlebarLeft">
                <div class="realData-headers">
                    <i class="el-icon-time"></i>
                    <label>{{ TimeShow }}</label>
                    <span>{{ showTip }}...</span>
                    <i class="el-icon-loading"></i>
                </div>
            </template>
        </my-Titlebar>
        <div :class="initParam.page_z>total?'realData-contaier':'realData-contaier-if'">
            <div class="realData-contaier-item" v-for="item,index in list" :key="index" data-aos="zoom-in" :data-aos-delay="index*100">
                <div class="realData-contaier-item-name">
                    <label>{{item.T_name}}</label>
                    <label>{{item.T_sn}}</label>
                </div>
                <div class="realData-contaier-item-main">
                    <div class="realData-contaier-item-main-top">
                        <div class="realData-contaier-item-main-top-left">
                            <div class="realData-contaier-item-main-top-leftYuan"></div>
                            <div
                            :style="{color:item.T_DeviceSensorData.T_tl<=item.T_DeviceSensorData.T_t && item.T_DeviceSensorData.T_tu>=item.T_DeviceSensorData.T_t?'':'red'}"
                            >{{item.T_DeviceSensorData.T_t}}℃</div>
                            <div
                            :style="{color:item.T_DeviceSensorData.T_rhl<=item.T_DeviceSensorData.T_rh && item.T_DeviceSensorData.T_rhu>=item.T_DeviceSensorData.T_rh?'':'red'}"
                            >{{item.T_DeviceSensorData.T_rh}}%</div>
                        </div>
                        <div class="realData-contaier-item-main-top-right">
                            <div style="cursor: pointer;" @dblclick="showDia(item)">温度阈值：{{item.T_DeviceSensorData.T_tl}}~{{item.T_DeviceSensorData.T_tu}}℃</div>
                            <div style="cursor: pointer;" @dblclick="showDia(item)">湿度阈值：{{item.T_DeviceSensorData.T_rhl}}~{{item.T_DeviceSensorData.T_rhu}}%</div>
                            <div>连接状态：{{item.T_link===1?'已连接':'已断开'}}</div>
                            <div>启用状态：{{item.T_DeviceSensorParameter.T_free==0?'已启用':item.T_DeviceSensorParameter.T_free==1?'空库':'未知'}}</div>
                        </div>
                    </div>
                    <div class="realData-contaier-item-main-bottom">
                        <div class="realData-contaier-item-main-bottom-left">
                            {{item.T_DeviceSensorData.T_time=='0001-01-01 00:00:00'?'暂无数据':item.T_DeviceSensorData.T_time}}
                        </div>
                        <div class="realData-contaier-item-main-bottom-right">
                            <img title="在线" v-if="item.T_online===1 || item.T_online_s===1"src="../../assets/img/wifi.svg" style="width:20px;height: 20px;margin-top: -2px;">
                            <img title="离线" v-else src="../../assets/img/noWifi.svg" style="width:20px;height: 20px;">
                            
                            <cells title="电量" :electric="item.T_Dattery"></cells>
                            
                            <img title="监控中" v-if="item.T_monitor==1 && item.T_link == 1"src="../../assets/img/monitoring.svg" 
                            @click.stop="setStartStop(item)" style="width:20px;height: 20px;margin-top: -2px;cursor: pointer;">
                            <img title="未监控" v-else src="../../assets/img/nomonitoring.svg" 
                            @click.stop="setStartStop(item)" style="width:20px;height: 20px;margin-top: -2px;cursor: pointer;">

                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <my-pagination v-if="list.length!=0 && initParam.page_z<total" style="margin: 10px auto 0 auto;" :total="total" :pageSizi="initParam.pageSize" data-aos="slide-up" :data-aos-delay="0"
            :PageNum.sync="initParam.page" @currentChange="currentChange"></my-pagination>

        <my-Modalbox :visible.sync="visible" titleBox="设备设置" iswidth="50%">
            <template slot="modalConter">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="传感器参数" name="first">
                        <div style="padding: 20px 0;max-height: 450px;overflow-y: auto;">
                            <el-form ref="form" :model="form" label-width="100px">
                                <el-form-item label="传感器名称">
                                    <div style="display: flex;align-items: center;">
                                        <el-input v-model="form.T_name"></el-input>
                                        <popovers content="设备名称在0-20个字节"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="传感器采样率">
                                    <div style="display: flex;align-items: center;">
                                        <el-input v-model="form.T_speed"></el-input>
                                        <popovers content="秒(1~240)"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="预警">
                                    <div style="display: flex;align-items: center;">
                                        <el-switch v-model="form.T_enprel" :inactive-value="0" :active-value="1"></el-switch>
                                        <popovers placement="right" content="推送预警消息，设为 空库后 不推送预警消息"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="温度范围">
                                    <div style="display: flex;align-items: center;">
                                        <div style="display: flex;align-items: center;">
                                            <el-input v-model="form.T_Tlower"></el-input>
                                            <span style="padding: 0 20px;">-</span>
                                            <el-input v-model="form.T_Tupper"></el-input>
                                        </div>
                                        <popovers content="-40.0 ~ 80.0" placement="right"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="湿度范围">
                                    <div style="display: flex;align-items: center;">
                                        <div style="display: flex;align-items: center;">
                                            <el-input v-model="form.T_RHlower"></el-input>
                                            <span style="padding: 0 20px;">-</span>
                                            <el-input v-model="form.T_RHupper"></el-input>
                                        </div>
                                        <popovers content="-40.0 ~ 80.0" placement="right"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="启用">
                                    <div style="display: flex;align-items: center;">
                                        <el-switch v-model="form.T_en" :inactive-value="0" :active-value="1"></el-switch>
                                        <popovers placement="right" content="功能（实时、记录、报警、预警）"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="空库">
                                    <div style="display: flex;align-items: center;">
                                        <el-switch v-model="form.T_free" :inactive-value="0" :active-value="1"></el-switch>
                                        <popovers placement="right" content="1.启用（正常 实时、记录, 不 报警、预警）2.关闭（正常 实时、记录、报警、预警）"></popovers>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div style="margin:20px 0 0 100px;">
                            <el-button type="primary" @click="subFrom1">立即提交</el-button>
                            <el-button @click="visible = fasle">取消</el-button>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="传感器配置" name="second">
                        <div style="padding: 20px 0;">
                            <el-form ref="form" :model="form" label-width="150px">
                                <el-form-item label="实时数据显示排序">
                                    <div style="display: flex;align-items: center;">
                                        <el-input v-model="form2.T_sort"></el-input>
                                        <popovers content="越小越靠前，可以为负数"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="数据视图3D视图订阅">
                                    <div style="display: flex;align-items: center;">
                                        <el-input v-model="form2.T_3dview"></el-input>
                                    </div>
                                </el-form-item>
                                <el-form-item label="类型选择">
                                    <div>
                                        <el-radio-group v-model="form2.T_type">
                                            <el-radio :label="0">默认</el-radio>
                                            <el-radio :label="1">物流</el-radio>
                                            <el-radio :label="2">药店</el-radio>

                                        </el-radio-group>
                                    </div>
                                </el-form-item>
                                
                                <el-form-item label="屏蔽数据展示">
                                    <div>
                                        <el-radio-group v-model="form2.T_datashow">
                                            <el-radio :label="0">屏蔽数据展示</el-radio>
                                            <el-radio :label="1">正常数据显示</el-radio>
                                        </el-radio-group>
                                    </div>
                                </el-form-item>
                                
                                <el-form-item>
                                    <el-button type="primary" @click="subFrom2">立即提交</el-button>
                                    <el-button @click="visible = fasle">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </template>
        </my-Modalbox>
        <my-Modalbox :visible.sync="stops" titleBox="远程启停验证" iswidth="50%">
            <div slot="modalConter" class="model-main">
                <Slides @slidesFn="slidesFn"></Slides>
                <div class="model-btn" @click="submit">
                    <img src="../../assets/img/btn.png">
                    <label>立即执行</label>
                </div>
            </div>
        </my-Modalbox>
    </div>
</template>

<script>
import {formatDate} from '@/utils/Times.js'
import cells from "../../components/cells.vue";
import popovers from "../../components/popovers.vue";
import Slides from '../../components/Slides.vue'

import { DevSenList } from "@/api/dataDisplay/chartShow"
import {DevTask,DevParGet,DevParParPu,DevSenEdit} from "@/api/equipmentManagement/management.js"
export default {
    name: "",
    components:{cells,popovers,Slides},
    data() {
        return {
            showTip:'实时更新中',
            showbtn:false,
            stops:false,
            visible:false,
            activeName: 'first',
            times:'',
            TimeShow:'2024-01-01 00:00:00',
            initParam:{
                page:1,
                page_z:10 ,
                T_RealTime: 1,
                T_name: '',
                T_calssid:'' 
            },
            form: {},
            form2:{},

            list:[],
            total:0,
            setTimeouts:null,
            desData:null
        }
    },
    created() {
    },
    mounted() {
        this.CurrentTime()
        this.GetDevSenListApi()
        this.setTimeouts = setInterval (() => {
            this.GetDevSenListApi()
        },2000);
    },
    methods:{
        currentChange(data){
            this.initParam.page = data
            this.GetDevSenListApi()
        },
       async GetDevSenListApi(){
            const reslut = await DevSenList(this.initParam)
            if(reslut.name && reslut.name =='AxiosError') {
                clearInterval(this.setTimeouts);this.showTip ='哎呀！发生异常了哟'
            }
            this.list = reslut.data.Data.DeviceSensor_lite || []
            this.total = reslut.data.Data.Num
        },
        submit(){
            if(!this.showbtn){
                this.$message.error('请输入验证信息')
                return
            }else{
                this.DevTask()
                this.stops = false
            }
        },
        slidesFn(e){//验证码
            this.showbtn = e
        },
        setStartStop(item){
            if(item.T_monitor === 2){
                this.$message.error('网络不佳')
                return
            }else{
                this.stops = true
                this.desData = item
            }
        },
        DevTask() { //远程启停
            var that = this
            if (this.desData.T_online == 1 || this.desData.T_online_s==1) {
                DevTask({
                    T_sn: this.desData.T_sn,
                    T_task: this.desData.T_monitor === 1 ? 'stop' : 'start'
                }).then(res => {
                    if (res.data.Code === 200 && res.data.Msg === "ok!") {
                        that.$message.success('远程启停，设置成功')
                        this.centerDialogVisible = false
                    }
                })
            } else {
                this.$message.error('设备离线')
            }
        },
        showDia(item){
            this.activeName = 'first'
            this.form2 = {
                T_id:item.T_id,
                T_sn:item.T_sn,
                T_sort:item.T_sort,
                T_type:item.T_type,
                T_datashow:item.T_datashow,
                T_3dview:item.T_3dview,
            }
            this.visible = true
            this.getPaGetApi(item)
        },
        subFrom1(){
            DevParParPu(this.form).then(res=>{
                console.log('提交1',res)
                if(res.data.Code==200){
                    this.visible = false
                    this.$message.success('操作成功，传感器参数设置成功')
                }
            })
        },
        subFrom2(){
            DevSenEdit(this.form2).then(res=>{
					if(res.data.Code==200){
						this.visible=false
						this.$message.success('操作成功，传感器配置成功')
					}
				})
        },
        getPaGetApi(item){//传感器参数获取数据
            DevParGet({
                T_id:item.T_id,
                T_sn:item.T_sn,
            }).then(res=>{
                console.log(res)
                if(res.data.Code==200){
                    // this.dialogVisible4=true
                    this.form = res.data.Data[0]
                }
            })
        },
        CurrentTime(){
            this.times = setInterval(()=>{
                this.TimeShow = formatDate(new Date())
            },1000)
        },
    },
    beforeDestroy(){
        clearInterval(this.times)
        clearInterval(this.setTimeouts)
    }
}
</script>
<style scoped>
::v-deep .el-tabs__item {
    color: #5fc5ff;
}
::v-deep .el-tabs__item.is-active {
    color: #5fc5ff !important;
}
::v-deep .el-tabs__nav-wrap::after {
    background-color: #183d7d;
}
::v-deep .el-form-item__label {
    color: #5fc5ff;
}
::v-deep .el-input__inner {
    background-color: #183d7d;
    border-radius: 4px;
    border: 1px solid #183d7d;
    color: #fff;
}
::v-deep .el-switch.is-checked .el-switch__core {
    border-color: #2f6bcf;
    background-color: #2f6bcf;
}
::v-deep .el-switch__core {
    border: 1px solid #183d7d;
    background: #183d7d;
}
::v-deep .el-radio {
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    outline: 0;
    margin-right: 30px;
}
</style>
<style lang="scss">
@import url('../../assets/scss/realData.scss');
</style>